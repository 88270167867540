import React from 'react';
import styled from 'styled-components';

const FAQHeader = () => {
    return (
        <div>
            <h1>FAQ</h1>
            <h2>A peak behind the curtain</h2>
        </div>
    )
}

export default FAQHeader;