import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import FAQHeader from "../components/molecules/FAQHeader"

const FaqPage = () => (
  <Layout>
    <SEO title="FAQ" />
    <FAQHeader />

</Layout>
)

export default FaqPage
